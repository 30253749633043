import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="media"
export default class extends Controller {
	static targets = ['list'];

	connect() {
		this.csrfToken = document.querySelector('meta[name=csrf-token]').content;
	}

	async promote(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		const options = {
			method: 'PATCH',
			headers: {
				'X-CSRF-Token': this.csrfToken,
				'Content-Type': 'application/json',
			},
		};

		const response = await fetch(evt.params.url, options);
		const data = await response.json();
		this.listTarget.outerHTML = data.html;
	}
}
