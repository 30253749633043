import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="collapse"
export default class extends Controller {
	static targets = ['collapsable', 'preview'];
	static values = { openClass: String };

	connect() {
		console.log('hello');
	}

	toggle(evt) {
		const arrow = evt.currentTarget;

		arrow.classList.toggle('-rotate-180');
		this.collapsableTarget.classList.toggle('h-0');
		this.collapsableTarget.classList.toggle(this.openClassValue);
	}
}
