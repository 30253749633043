import { Controller } from '@hotwired/stimulus';
import { Swiper } from 'swiper';
// import 'swiper/css';

// Connects to data-controller="swiper"
export default class extends Controller {
	connect() {
		this.swiper = new Swiper(this.element, {
			loop: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
				},
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		});
	}

	next() {
		this.swiper.slideNext();
	}

	prev() {
		this.swiper.slidePrev();
	}
}
