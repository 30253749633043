import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="modals--video"
export default class extends Controller {
	static targets = ['modal', 'content'];

	connect() {
		console.log(this.modalTarget);
		console.log(this.contentTarget);
	}

	toggle(evt) {
		this.modalTarget.classList.toggle('hidden');
		this.modalTarget.classList.toggle('flex');
	}

	close(evt) {
		this.modalTarget.classList.add('hidden');
		this.modalTarget.classList.remove('flex');
	}
}
