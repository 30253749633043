import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="modals--video"
export default class extends Controller {
	static targets = ['modal', 'title', 'description'];

	connect() {}

	toggle(evt) {
		this.modalTarget.classList.toggle('hidden');
		this.modalTarget.classList.toggle('flex');

		this._fullfill(evt.params.medium);
	}

	close(evt) {
		this.modalTarget.classList.add('hidden');
		this.modalTarget.classList.remove('flex');
	}

	_fullfill(medium) {
		this.titleTarget.innerHTML = medium.title;
		this.descriptionTarget.innerHTML = medium.description;
	}
}
