import { Controller } from '@hotwired/stimulus';
import Vimeo from '@vimeo/player';

// Connects to data-controller="vimeo"
export default class extends Controller {
	static targets = ['player'];
	static values = { url: String };

	connect() {
		this.playerTarget.onload = (evt) => {
			console.log(evt.currentTarget.contentWindow.document);
		};
	}
}
